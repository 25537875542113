<template>
	<v-sheet class="customer" style="height: calc(100vh - 88px)">
		<template v-if="contentLoaded">
			<v-layout class="align-center light-blue-bg px-4 py-2">
				<v-flex md12>
					<div class="d-flex">
						<p class="my-auto font-level-3-bold">
							<ShowValue :object="customer" object-key="display_name" label="customer name"></ShowValue>
							<v-chip color="cyan" class="ml-2 mr-2" label text-color="white">{{
								customer.barcode
							}}</v-chip>
						</p>
						<v-spacer></v-spacer>

						<v-btn
							v-if="getPermission('customer:create')"
							v-on:click="doAction('clone')"
							color="blue darken-4 text-white"
							class="ml-2"
							depressed
							tile
							><v-icon small left>mdi-content-duplicate</v-icon>Clone</v-btn
						>

						<v-btn
							v-if="getPermission('customer:update')"
							v-on:click="doAction('edit')"
							color="blue darken-4 text-white"
							class="ml-2"
							depressed
							tile
							><v-icon small left>mdi-pencil</v-icon>Edit</v-btn
						>

						<template v-if="getPermission('customer:delete')">
							<v-btn
								v-if="!customer.is_owner"
								v-on:click="doAction('delete')"
								color="blue darken-4 text-white"
								class="ml-2"
								depressed
								tile
								><v-icon small left>mdi-delete</v-icon>Delete</v-btn
							>
						</template>
						<template v-if="!customer.is_owner">
							<template v-if="getPermission('customer:delete')">
								<v-btn
									v-if="customer.activated"
									v-on:click="doAction('deactivate')"
									color="blue darken-4 text-white"
									class="ml-2"
									depressed
									tile
									><v-icon small left>mdi-cancel</v-icon>De-activate</v-btn
								>
								<v-btn
									v-else
									v-on:click="doAction('activate')"
									color="blue darken-4 text-white"
									class="ml-2"
									depressed
									tile
									><v-icon small left>mdi-check-all</v-icon>Activate</v-btn
								>
							</template>
						</template>
						<template v-if="false && getPermission('property:create')">
							<v-btn
								v-if="customerTab == 'address-book'"
								v-on:click="doAction('new-address')"
								color="blue darken-4 text-white"
								class="ml-2"
								depressed
								tile
								><v-icon small left>mdi-playlist-plus</v-icon>Add New Property</v-btn
							>
						</template>
					</div>
					<div class="d-flex mt-4">
						<div class="w-10 pr-3">
							<ImageTemplate circle :src="customer.image"></ImageTemplate>
						</div>
						<div class="w-100 pr-3">
							<div class="d-flex mt-4">
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Customer # : </span>
										<span class="fw-500">
											<ShowValue :object="customer" object-key="barcode" label="customer #"></ShowValue
										></span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600 mr-2">Company Name : </span>
										<span class="fw-500">
											<span class="fw-500">
												<ShowValue
													:object="customer"
													object-key="company_name"
													label="company name"
												></ShowValue>
											</span>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Display Name : </span>
										<span class="fw-500">
											<ShowValue :object="customer" object-key="display_name" label="display name"></ShowValue>
										</span>
									</div>
								</div>
							</div>
							<div class="d-flex">
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Email Address : </span>
										<span class="fw-500">
											<ShowValue :object="customer" object-key="email" label="email address"></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600 mr-2">Phone : </span>
										<span class="fw-500">
											<ShowValue :object="customer" object-key="phone" label="phone"></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">UEN : </span>
										<span class="fw-500">
											<ShowValue :object="customer" object-key="uen" label="UEN"></ShowValue>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</v-flex>
			</v-layout>
			<div>
				<v-tabs
					v-model="customerTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#issued-assets">
						<v-icon small left>mdi-share-outline</v-icon> Issued Assets</v-tab
					>
					<v-tab href="#linked-members">
						<v-icon small left>mdi-account</v-icon> Incharge Officers</v-tab
					>
					<v-tab v-if="false" href="#detail">
						<v-icon small left>mdi-format-list-bulleted-square</v-icon> Details</v-tab
					>
					<v-tab href="#file"> <v-icon small left>mdi-file-document-multiple</v-icon> Files</v-tab>
					<v-tab href="#checked-out" v-if="false">
						<v-icon small left>mdi-share-outline</v-icon> Checked Out</v-tab
					>
					<v-tab href="#reservation" v-if="false">
						<v-icon small left>mdi-calendar-check</v-icon> Reservations</v-tab
					>
					<v-tab href="#address-book"> <v-icon small left>mdi-format-list-text</v-icon> Address</v-tab>
					<v-tab href="#event"> <v-icon small left>mdi-history</v-icon> Logs</v-tab>
				</v-tabs>

				<v-tabs-items v-model="customerTab">
					<v-tab-item value="issued-assets">
						<div>
							<v-layout class="p-4 border-bottom-light-grey min-height-57px">
								<v-flex class="font-level-3-bold my-auto">
									<span class="detail-svg-icon mr-2">
										<!--begin::Svg Icon-->
										<inline-svg :src="$assetURL('media/custom-svg/asset-color.svg')" />
										<!--end::Svg Icon-->
									</span>
									Issued Assets
								</v-flex>
								<v-flex>
									<v-layout class="justify-end">
										<div class="mx-2" style="max-width: 220px">
											<text-input
												v-on:click:clear="
													s_search['search'] = null;
													getTransactionAssets();
												"
												clearable
												hide-details
												hide-top-margin
												custom-class="pt-0"
												placeholder="Search"
												multiple
												v-model="s_search['search']"
											></text-input>
										</div>
										<div class="mx-2" style="max-width: 400px">
											<date-range-picker
												v-on:click:clear="
													s_search['date-range'] = [];
													getTransactionAssets();
												"
												hide-details
												hide-top-margin
												clearable
												v-model="s_search['date-range']"
											></date-range-picker>
										</div>
										<div class="mx-2" style="max-width: 220px">
											<select-input
												v-on:click:clear="
													s_search['incharge-officer'] = [];
													getTransactionAssets();
												"
												clearable
												:items="incharge_items"
												hide-details
												custom-class="pt-0"
												placeholder="Incharge Officer"
												multiple
												v-model="s_search['incharge-officer']"
											></select-input>
										</div>
										<div class="d-flex">
											<v-btn
												color="blue darken-4 text-white"
												class="mx-2"
												depressed
												tile
												:disabled="pageLoading"
												v-on:click="getTransactionAssets()"
												><v-icon center> mdi-magnify </v-icon></v-btn
											>
										</div>
									</v-layout>
								</v-flex>
							</v-layout>
						</div>
						<div class="bt-table internal-table-listing">
							<table width="100%" style="table-layout: fixed">
								<thead>
									<tr>
										<th width="26.66%">Asset</th>
										<th width="26.66%">Incharge Officer</th>
										<th width="26.66%">Dates</th>
										<th width="10%">Issued PDF</th>
										<th width="10%">Received PDF</th>
									</tr>
								</thead>
								<tbody v-if="transaction_loading">
									<tr>
										<td colspan="5">
											<br />
											<br />
											<br />
											<div class="px-10">
												<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
											</div>
										</td>
									</tr>
								</tbody>
								<template v-else>
									<tbody v-if="transaction_asset.length">
										<tr
											v-for="(row, index) in transaction_asset"
											:key="index"
											:class="{ 'light-border-bottom': transaction_asset[index + 1] }"
										>
											<td align="center" class="py-2">
												<v-layout class="align-center">
													<v-flex md3>
														<ImageTemplate circle style="max-width: 50px" :src="row.asset_image"></ImageTemplate>
													</v-flex>
													<v-flex md9 class="text-left">
														<Chip :text="row.asset_barcode" color="blue darken-4"></Chip>
														<v-tooltip top content-class="custom-top-tooltip">
															<template v-slot:activator="{ on, attrs }">
																<v-icon
																	:color="row.is_received ? 'green' : 'orange'"
																	class="ml-2 py-0 my-0"
																	large
																	v-bind="attrs"
																	v-on="on"
																	>mdi-record</v-icon
																>
															</template>
															<span> {{ row.is_received ? "Received" : "Issued" }} </span>
														</v-tooltip>
														<p class="m-0" style="white-space: pre-wrap"><b>Name: </b> {{ row.asset_name }}</p>
													</v-flex>
												</v-layout>
											</td>
											<td align="center" class="py-2">
												<v-layout class="align-center">
													<v-flex md3>
														<ImageTemplate circle style="max-width: 50px" :src="row.user_image"></ImageTemplate>
													</v-flex>
													<v-flex md9 class="text-left">
														<Chip :text="row.user_barcode" color="blue darken-4"></Chip>
														<p class="m-0" style="white-space: pre-wrap">
															<b>Name: </b> {{ row.user_display_name }}
														</p>
													</v-flex>
												</v-layout>
											</td>
											<td class="py-2">
												<div>
													<p class="m-0 custom-nowrap-ellipsis">
														<b>From: </b> {{ formatDateTime(row.reserve_from) }}
													</p>
													<p class="m-0 custom-nowrap-ellipsis" v-if="row.reserve_to">
														<b>To: </b> {{ formatDateTime(row.reserve_to) }}
													</p>
													<p class="m-0 custom-nowrap-ellipsis" v-if="row.is_received && row.received_on">
														<b>Received: </b> {{ formatDateTime(row.received_on) }}
													</p>
												</div>
											</td>
											<td class="py-2" align="center">
												<v-icon
													v-on:click.stop.prevent="downloadFile(row.issued_pdf)"
													:class="row.issued_pdf ? 'red--text' : 'grey--text'"
													large
													>mdi-file-pdf-box</v-icon
												>
											</td>
											<td class="py-2" align="center">
												<v-icon
													v-on:click.stop.prevent="downloadFile(row.received_pdf)"
													:class="row.received_pdf ? 'red--text' : 'grey--text'"
													large
													>mdi-file-pdf-box</v-icon
												>
											</td>
										</tr>
									</tbody>
									<tfoot v-else>
										<tr>
											<td colspan="4" align="center">
												<p class="py-2 px-2">Nothing Found...</p>
											</td>
										</tr>
									</tfoot>
								</template>
							</table>
						</div>
					</v-tab-item>
					<v-tab-item value="linked-members">
						<div>
							<v-layout class="p-4 border-bottom-light-grey min-height-57px">
								<v-flex class="font-level-3-bold my-auto">
									<span class="detail-svg-icon mr-2">
										<!--begin::Svg Icon-->
										<inline-svg :src="$assetURL('media/custom-svg/asset-color.svg')" />
										<!--end::Svg Icon-->
									</span>
									Incharge Officers
								</v-flex>
								<v-flex class="text-right">
									<v-btn color="blue darken-4 white--text" depressed tile v-on:click="open_manage_dialog()"
										>Manage</v-btn
									>
								</v-flex>
							</v-layout>
						</div>
						<div class="bt-table internal-table-listing">
							<table width="100%" style="table-layout: fixed">
								<thead>
									<tr>
										<th width="100%">Incharge Officer</th>
									</tr>
								</thead>
								<tbody v-if="incharge_officer.length">
									<tr v-for="(row, index) in incharge_officer" :key="index">
										<td width="100%" align="center" class="py-2">
											<v-layout class="align-center">
												<v-flex md3>
													<ImageTemplate circle style="max-width: 50px" :src="row.user_image"></ImageTemplate>
												</v-flex>
												<v-flex md9 class="text-left">
													<Chip :text="row.user_barcode" color="blue darken-4"></Chip>
													<p class="m-0" style="white-space: pre-wrap">
														<b>Name: </b> {{ row.user_display_name }}
													</p>
												</v-flex>
											</v-layout>
										</td>
									</tr>
								</tbody>
								<tfoot v-else>
									<tr>
										<td class="py-4" align="center">Nothing Found...</td>
									</tr>
								</tfoot>
							</table>
						</div>
					</v-tab-item>
					<v-tab-item v-if="false" value="detail">
						<v-expansion-panels
							flat
							accordion
							tile
							class="mt-5 transparent-panels overflow-y"
							style="max-height: calc(100vh - 250px)"
						>
							<v-expansion-panel class="active">
								<v-expansion-panel-header v-if="false"> Customer Details </v-expansion-panel-header>
								<v-expansion-panel-content v-if="false">
									<table width="100%" class="detail-table scattered-table">
										<tr>
											<th width="250px">Customer #</th>
											<td>
												<ShowValue :object="customer" object-key="barcode" label="customer #"></ShowValue>
											</td>
											<th width="250px">UEN</th>
											<td>
												<ShowValue :object="customer" object-key="uen" label="UEN"></ShowValue>
											</td>
										</tr>
										<tr>
											<th width="250px">Company Name</th>
											<td>
												<ShowValue
													:object="customer"
													object-key="company_name"
													label="company name"
												></ShowValue>
											</td>
											<th width="250px">Display Name</th>
											<td>
												<ShowValue
													:object="customer"
													object-key="display_name"
													label="display name"
												></ShowValue>
											</td>
										</tr>
										<tr>
											<th width="250px">Email Address</th>
											<td>
												<ShowValue :object="customer" object-key="email" label="email address"></ShowValue>
											</td>
											<th width="250px">Phone</th>
											<td>
												<ShowValue :object="customer" object-key="phone" label="phone"></ShowValue>
											</td>
										</tr>
										<tr v-if="false">
											<th width="250px">Primary Contact</th>
											<td>
												<ShowValue
													:object="customer"
													object-key="primary_contact"
													label="primary contact"
												></ShowValue>
											</td>
											<th width="250px">Website</th>
											<td>
												<ShowValue :object="customer" object-key="website" label="website"></ShowValue>
											</td>
										</tr>
										<tr v-if="false">
											<th width="250px">Fax</th>
											<td>
												<ShowValue :object="customer" object-key="fax" label="fax"></ShowValue>
											</td>
											<th width="250px">
												Remarks <br /><span class="text-muted font-small">(For Internal Use)</span>
											</th>
											<td>
												<ShowValue :object="customer" object-key="remarks" label="remarks"></ShowValue>
											</td>
										</tr>
									</table>
								</v-expansion-panel-content>
							</v-expansion-panel>
							<v-expansion-panel>
								<v-expansion-panel-header>
									<v-layout>
										<v-flex md10>Contact Persons ({{ contact_person.length }})</v-flex>
										<v-flex md2 class="text-right">
											<v-icon v-on:click.stop.prevent="addPerson()" dense color="blue darken-4" class="mx-2"
												>mdi-plus-circle-outline</v-icon
											>
										</v-flex>
									</v-layout>
								</v-expansion-panel-header>
								<v-expansion-panel-content class="contact-person-list">
									<template v-if="contact_person.length">
										<v-layout v-for="(person, index) in contact_person" :key="index">
											<v-flex md12>
												<div>
													<p class="text-truncate mb-1 bold-600 text-capitalize">
														{{ person.full_name }}
														<v-chip class="ma-2" label x-small color="green" v-if="person.primary == 1">
															<span class="white--text">Primary</span>
														</v-chip>
													</p>
													<v-menu left tile offset-x>
														<template v-slot:activator="{ on, attrs }">
															<v-btn
																icon
																small
																color="blue darken-4"
																class="btx-icon-top-right"
																v-bind="attrs"
																v-on="on"
															>
																<v-icon small>mdi-cog</v-icon>
															</v-btn>
														</template>

														<v-list small>
															<v-list-item link v-on:click="addPerson(person)">
																<v-list-item-title>Edit</v-list-item-title>
															</v-list-item>
															<v-list-item link v-if="!person.primary" v-on:click="markAsPrimary(person.uuid)">
																<v-list-item-title>Mark as Primary</v-list-item-title>
															</v-list-item>
															<v-list-item link v-if="false">
																<v-list-item-title>Send Email</v-list-item-title>
															</v-list-item>
															<v-list-item link v-if="!person.primary" v-on:click="deletePerson(person.uuid)">
																<v-list-item-title>Delete</v-list-item-title>
															</v-list-item>
														</v-list>
													</v-menu>
												</div>
												<p class="mb-1 text-lowercase" v-if="person.email">
													<v-icon left small>mdi-email</v-icon>{{ person.email }}
												</p>
												<p class="mb-1 text-lowercase" v-if="person.phone">
													<v-icon left small>mdi-phone</v-icon>{{ person.phone }}
												</p>
												<p class="mb-1" v-if="person.display_name">
													<v-icon left small>mdi-account</v-icon> {{ person.display_name }}
												</p>
												<p class="mb-1" v-if="person.designation">
													<v-icon left small>mdi-briefcase</v-icon> {{ person.designation }}
												</p>
											</v-flex>
										</v-layout>
									</template>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</v-tab-item>
					<v-tab-item value="file">
						<Files
							v-if="customerTab == 'file'"
							class="mx-4"
							type-text="Customer Files"
							type="contact"
							:type-uuid="uuid"
						></Files>
					</v-tab-item>
					<v-tab-item value="checked-out" v-if="false">
						<ReservationListing
							v-if="customerTab == 'checked-out'"
							class="mx-4"
							type-text="Customer"
							type="customer"
							:type-uuid="uuid"
							status="checked-out"
						></ReservationListing>
					</v-tab-item>
					<v-tab-item value="reservation" v-if="false">
						<ReservationListing
							v-if="customerTab == 'reservation'"
							class="mx-4"
							type-text="Customer"
							type="customer"
							:type-uuid="uuid"
							status="reserved"
						></ReservationListing>
					</v-tab-item>
					<v-tab-item value="address-book">
						<CustomerAddressBook
							v-if="customerTab == 'address-book'"
							type-text="Customer"
							type="contact"
							:new-address="addressDialog"
							:type-uuid="uuid"
							:contact-person-list="contact_person"
							v-on:close="addressDialog = false"
							v-on:updateContactPersonList="getCustomer"
						></CustomerAddressBook>
					</v-tab-item>
					<v-tab-item value="event">
						<EventListing
							v-if="customerTab == 'event'"
							class="mx-4"
							type-text="Customer"
							type="contact"
							:type-uuid="uuid"
						></EventListing>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<DeleteTemplate
				type="Customer"
				:delete-text="deleteText"
				v-on:success="goBack()"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="deleteURL"
				:delete-note="
					customer.activated
						? 'To preserve associated records, you could de-activate the Customer instead.'
						: null
				"
			>
				<template v-if="customer.activated" v-slot:extra-btn>
					<v-btn
						class="white--text"
						depressed
						color="blue darken-4"
						tile
						v-on:click="
							deleteDialog = false;
							deactivateDialog = true;
						"
					>
						De-activate
					</v-btn>
				</template>
			</DeleteTemplate>
			<DeactivateTemplate
				type="Customer"
				:deactivate-text="deactivateText"
				v-on:success="goBack()"
				v-on:close="deactivateDialog = false"
				:deactivate-dialog="deactivateDialog"
				:deactivate-url="deactivateURL"
			>
			</DeactivateTemplate>
			<ActivateTemplate
				type="Customer"
				:activate-text="activateText"
				v-on:success="goBack()"
				v-on:close="activateDialog = false"
				:activate-dialog="activateDialog"
				:activate-url="activateURL"
			>
			</ActivateTemplate>
			<CreateOrUpdatePerson
				:person-dialog="personDialog"
				:vendor-uuid="uuid"
				:person="personModel"
				:contactUuid="customer.uuid"
				v-on:close="personDialog = false"
				v-on:success="getCustomer"
			></CreateOrUpdatePerson>
			<Dialog :dialog="manage_ic_dialog">
				<template v-slot:title> Manage Incharge Officers </template>
				<template v-slot:body>
					<div class="mb-4">
						<AutoCompleteInput
							multiple
							hide-details
							:items.sync="incharge_items"
							placeholder="Incharge Officers"
							v-model="db_incharge_items"
							:disabled="incharge_loading"
							:loading="incharge_loading"
						></AutoCompleteInput>
					</div>
				</template>
				<template v-slot:action>
					<v-btn
						:disabled="incharge_loading"
						v-on:click="saveInchargeOfficers()"
						:loading="incharge_loading"
						color="blue darken-4 white--text"
						depressed
						tile
						>Save</v-btn
					>
					<v-btn :disabled="incharge_loading" depressed tile v-on:click="manage_ic_dialog = false"
						>Close</v-btn
					>
				</template>
			</Dialog>
		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
	</v-sheet>
</template>
<style>
.w-15 {
	width: 125px;
}
</style>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
	GetCustomer,
	MarkAsDefaultContactPerson,
	DeleteContactPerson,
} from "@/core/lib/contact.lib";
import ImageTemplate from "@/view/components/Image";
import ShowValue from "@/view/components/ShowValue";
import Dialog from "@/view/components/Dialog";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import { isEmpty } from "lodash";
import ActivateTemplate from "@/view/components/ActivateTemplate";
import DeactivateTemplate from "@/view/components/DeactivateTemplate";
import EventListing from "@/view/components/EventListing";
import ReservationListing from "@/view/components/ReservationListing";
import CustomerAddressBook from "@/view/components/MemberAddressBook";
import Files from "@/view/components/Files";
import CreateOrUpdatePerson from "@/view/components/CreateOrUpdatePerson";
import SelectInput from "@/view/components/SelectInput";
import DateRangePicker from "@/view/components/DateRangePicker";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TextInput from "@/view/components/TextInput";
import ApiService from "@/core/services/api.service";
import Chip from "@/view/components/Chip";

export default {
	name: "customer-detail",
	title: "Detail Customer",
	data() {
		return {
			uuid: null,
			barcode: null,
			activateText: null,
			activateURL: null,
			deactivateText: null,
			deactivateURL: null,
			personDialog: false,
			deleteText: null,
			contact_person: [],
			transaction_asset: [],
			incharge_officer: [],
			incharge_items: [],
			db_incharge_items: [],
			incharge_loading: false,
			manage_ic_dialog: false,
			transaction_loading: false,
			formValid: true,
			personModel: {},
			deleteURL: null,
			deleteDialog: false,
			addressDialog: false,
			deactivateDialog: false,
			activateDialog: false,
			contentLoaded: false,
			pageLoading: false,
			customerTab: "issued-assets",
			customer: {},
			s_search: {
				search: null,
				"date-range": [],
				"incharge-officer": [],
			},
		};
	},
	methods: {
		open_manage_dialog() {
			this.db_incharge_items = [];
			for (let i = 0; i < this.incharge_officer.length; i++) {
				this.db_incharge_items.push(this.incharge_officer[i].user_id);
			}
			console.log({ db_incharge_items: this.db_incharge_items });
			this.manage_ic_dialog = true;
		},
		downloadFile(url) {
			if (!url) {
				return false;
			}
			window.open(url, "_blank");
		},
		getTransactionAssets() {
			this.transaction_loading = true;
			ApiService.setHeader();
			ApiService.query(`contact/${this.uuid}/assets`, this.s_search)
				.then(({ data }) => {
					this.transaction_asset = data;
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.transaction_loading = false;
				});
		},
		saveInchargeOfficers() {
			this.incharge_loading = true;
			ApiService.setHeader();
			ApiService.patch(`contact/${this.uuid}/incharge-officer`, { members: this.db_incharge_items })
				.then(() => {
					this.getCustomer();
					this.manage_ic_dialog = false;
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.incharge_loading = false;
				});
		},
		doAction(param) {
			switch (param) {
				case "edit":
					this.$router.push({
						name: "customer-update",
						params: { uuid: this.uuid },
						query: { t: new Date().getTime() },
					});
					break;
				case "clone":
					this.$router.push({
						name: "customer-create",
						query: { clone: this.uuid, t: new Date().getTime() },
					});
					break;
				case "delete":
					this.deleteDialog = true;
					break;
				case "deactivate":
					this.deactivateDialog = true;
					break;
				case "activate":
					this.activateDialog = true;
					break;
				case "new-address":
					this.addressDialog = true;
					break;
				case "print-label":
					break;
			}
		},
		markAsPrimary(uuid) {
			MarkAsDefaultContactPerson(uuid)
				.then(() => {
					this.getCustomer();
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
		deletePerson(uuid) {
			DeleteContactPerson(uuid)
				.then(() => {
					this.getCustomer();
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
		getCustomer() {
			GetCustomer(this.uuid)
				.then((data) => {
					const customer = data.contact;
					this.barcode = customer.barcode;
					this.customer = customer;
					this.contact_person = data.contact_person;
					this.incharge_officer = data.incharge_officer;
					this.deleteText = `#${customer.barcode} - ${customer.display_name}`;
					this.deleteURL = `contact/${customer.uuid}`;
					this.activateText = `${customer.display_name}`;
					this.activateURL = `contact/customer/${customer.uuid}/activate`;
					this.deactivateText = `${customer.display_name}`;
					this.deactivateURL = `contact/customer/${customer.uuid}/de-activate`;
					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Contacts", disabled: true },
						{ text: "Customer", disabled: true },
						{ text: "Detail", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
					this.getTransactionAssets();
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
		addPerson(person) {
			this.personDialog = true;
			this.personModel = new Object();
			if (person && !isEmpty(person)) {
				this.personModel = new Object({
					id: person.id || null,
					uuid: person.uuid || null,
					salutation: person.salutation || null,
					first_name: person.first_name || null,
					last_name: person.last_name || null,
					email: person.email || null,
					mobile: person.phone || null,
					display_name: person.display_name || null,
					designation: person.designation || null,
					primary: person.primary || null,
				});
			}
		},
	},
	components: {
		Chip,
		ShowValue,
		SelectInput,
		TextInput,
		DateRangePicker,
		AutoCompleteInput,
		CustomerAddressBook,
		EventListing,
		ImageTemplate,
		Dialog,
		DeleteTemplate,
		ActivateTemplate,
		CreateOrUpdatePerson,
		Files,
		DeactivateTemplate,
		ReservationListing,
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Contacts", disabled: true },
			{ text: "Customer", disabled: true },
			{ text: "Detail", disabled: true },
		]);

		const { uuid } = this.$route.params;
		this.uuid = uuid;
		if (!this.uuid) {
			this.goBack();
		}
		this.getCustomer();

		this.incharge_items = this.localDB("members", []);
	},
	computed: {
		...mapGetters(["localDB"]),
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
